<template>
  <div>
    <v-breadcrumbs :items="breadcrumbItems"/>

    <v-card v-if="org">
      <v-card-title>Events in this organization</v-card-title>
      <v-card-text>
        <v-btn color="primary" :to="{name:'eventmanagerCreate', query: {groupId: org.id}}">Create a new event</v-btn>
      </v-card-text>
      <v-card-text v-if="!events || events.length == 0">
        No events yet
      </v-card-text>
      <div v-else>
        <v-card-text>
          These are the events within this organization. Click an event to view the details of the event.
        </v-card-text>

        <v-card-title>Active Events</v-card-title>
        <EventGrid :routeResolver="resolveEventManagerRoute" :items="activeEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        <v-card-text v-if="!activeEvents || activeEvents.length == 0">
          No active events currently.
        </v-card-text>

        <v-card-title>Upcoming Events</v-card-title>
        <EventGrid :routeResolver="resolveEventManagerRoute" :items="upcomingEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        <v-card-text v-if="!upcomingEvents || upcomingEvents.length == 0">
          No upcoming events have been created yet.
        </v-card-text>

        <v-card-title>Completed Events</v-card-title>
        <EventGrid :routeResolver="resolveEventManagerRoute" :items="pastEvents" icon="fa-pencil-alt" :minimal="false" class="mx-4 mb-4"></EventGrid>
        <v-card-text v-if="!pastEvents || pastEvents.length == 0">
          No completed events yet.
        </v-card-text>
      </div>

      <div>
        <v-card-title>User Created Events</v-card-title>
        <EventGrid :routeResolver="resolveEventManagerRoute" :items="userCreatedEvents" icon="fa-pencil-alt" :minimal="true" class="mx-4 mb-4"></EventGrid>
        <v-card-text v-if="!userCreatedEvents || userCreatedEvents.length == 0">
          No user created events yet.
        </v-card-text>
      </div>

    </v-card>
  </div>      
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import orgManagerService from "@/services/orgManagerService";
import { EventBus } from '@/plugins/eventbus.js';
import EventGrid from "@/components/EventGrid.vue";
import siteData from '@/data/site.json'
import OrgUtil from "@/util/orgUtil";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Org",
  components: {
    EventGrid,
  },
  props: {
    org: Object,
  },
  data() {
    return {
      tenant: tenant,
      error: null,
      events: null,
      userCreatedEvents: null,
    };
  },
  async mounted() {
    await this.getData();
    
    EventBus.$on('login-state-change', async user => {
      await this.getData();
    });
  },
  methods: {
    async getGroup(id) {
      var response = await orgManagerService.getEvents(id);
      this.events = response.data.data;
      this.userCreatedEvents = (await orgManagerService.getEvents(id, true)).data.data
    },

    async getData() {
      if (this.user) {
        await this.getGroup(this.$route.params.id);
      }
    },

    resolveEventManagerRoute(eventId) {
      return {name: 'eventmanagerView', params: {id: eventId}}
    },

  },
  computed: {
    
    breadcrumbItems() {
      return !this.org ? [] : [
        { text: 'Event Manager', exact: true, to: { name: 'eventmanager' } },
        { text: 'Organizations', exact: true, to: { name: 'orgmanager' } },
        { text: this.org.name, exact: true, to: { name: 'orgmanagerView', params: {id: this.org.id}} },
        { text: 'Events', disabled: true },
      ];
    },
    orgUtil() {
      return new OrgUtil(this, this.org);
    },
    activeEvents() {
      return this.events && this.events.filter(x => this.$helpers.isActiveEvent(x)).sort((a,b) => a.from <= b.from);
    },
    upcomingEvents() {
      return this.events && this.events.filter(x => this.$helpers.isFutureEvent(x)).sort((a,b) => a.from <= b.from);
    },
    pastEvents() {
      return this.events && this.events.filter(x => this.$helpers.isPastEvent(x)).sort((a,b) => a.from > b.from);
    },
    ...mapGetters({
      user: "user"
    })
  },
  
};
</script>

